import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  message,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Typography,
  Row, Col
} from 'antd';
import {
  CloseCircleOutlined,
  EuroCircleOutlined,
  UserOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../styles/styles.css';
import {useCreateNewManualLineApiMutation } from '../../../../services/conciliationLines';
import { defaultRequiredRules} from '../../../../utils/Utils';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const DEVOLUTION_OPERATION_TYPE = 'DEVOLUTION';
const CHARGE_OPERATION_TYPE = 'CHARGE';

export default function NewManualLinesModal({
  conciliation, handleCancel, handleOk, hotel, jobId
}) {
  const { t } = useTranslation();

  const applyFilters = useSelector((state) => state.reducerFiltersPreConciliation);
  console.log("filtros aplicados", applyFilters);
  const hotelOptions = [];
  const hotels = [];
  const [form] = Form.useForm();

  const [
    createManualLine,
    {
      data: createManualLineResult,
      isLoading: isLoadingCreateManualLine,
      error: errorCreateManualLine,
    }
  ] = useCreateNewManualLineApiMutation();

  const onFinishForm = async (values) => {
    const operationTypeMultiplier = values?.operationType === DEVOLUTION_OPERATION_TYPE ? 1 : -1;

    const promises = [];
    values?.conciliationManualLines?.forEach(async (conciliationLine) => {
      promises.push(createManualLine({
        idJob: jobId,
        idHotel: values?.hotelCode,
        concept: conciliationLine?.concept,
        reservation: values?.localizator,
        nameHotel: hotels?.find((i) => i.value === form.getFieldValue('hotelCode')).text,
        amount: operationTypeMultiplier * Number(conciliationLine?.serviceAmount),
        paymentAmount: values?.paymentAmount,
        type: values?.typeOfConcept,
        observation: values?.observation,
      }));
    });
    const result = await Promise.all(promises);
    console.log(result);
  };

  useEffect(() => {
    if (errorCreateManualLine || createManualLineResult?.code === 1) {
      message.error(errorCreateManualLine?.message ?? createManualLineResult?.message);
    } else if (createManualLineResult) {
      message.success(createManualLineResult?.message);
      handleOk();
    }
  }, [createManualLineResult, errorCreateManualLine]);

  const onOk = () => {
    form.submit();
  };

  const operationTypes = [
    { label: 'Devolución al cliente', value: DEVOLUTION_OPERATION_TYPE },
    { label: 'Cargo al cliente', value: CHARGE_OPERATION_TYPE },
  ];

  conciliation?.lineas?.forEach((i) => {
    if (i.hotel !== null && i.hotel.length > 0) {
      if (!hotels.some(
        (item) => item.value === i.id_hotel,
      )) {
        hotelOptions.push(
          <Option key={i.id_hotel} value={i.id_hotel}>
            <Text className="text-option-value">
              {i.hotel}
            </Text>
          </Option>
        );
        hotels.push({
          text: i.hotel,
          value: i.id_hotel,
        });
      }
    }
  });

  return (
    <Modal
      title={(
        <Text className="text-title-popconfirm">
          {t('text_add_manual_lines')}
        </Text>
      )}
      open
      onCancel={handleCancel}
      closeIcon={<CloseCircleOutlined className="icon" />}
      onOk={onOk}
      okText={t('text_add_manual_lines')}
      confirmLoading={isLoadingCreateManualLine}
    >
      <Form
        name="manual_line"
        initialValues={{
          remember: true,
          size: 'middle',
          hotelCode: hotel,
          operationType: 'DEVOLUTION',
        }}
        form={form}
        onFinish={onFinishForm}

      >
        <Form.Item
          name="hotelCode"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            disabled={hotel !== null}
            className="select-modal"
            placeholder={t('hint_hotel_name')}
          >
            {hotelOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name="operationType"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            className="select-modal"
            allowClear
            placeholder={t('operation_type')}
            optionFilterProp="children"
            fieldNames={{ label: 'label', value: 'value' }}
            options={operationTypes}
          />
        </Form.Item>
        <Form.Item
          name="localizator"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Input
            prefix={<UserOutlined className="icon" />}
            placeholder={t('text_booking_locator')}
            className="input-modal"
          />
        </Form.Item>
        <Form.Item
          name="typeOfConcept"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            className="select-modal"
            allowClear
            placeholder={t('operation_type_lm')}
            optionFilterProp="children"
            fieldNames={{ label: 'label', value: 'value' }}
            options={applyFilters.concepts_lm}
          />
        </Form.Item>

        <div className="mt-3">
          <Form.List
            name="conciliationManualLines"
            rules={[
              {
                validator: async (_, conciliationManualLine) => {
                  if (!conciliationManualLine || conciliationManualLine.length < 1) {
                    return Promise.reject(new Error('Al menos debe existir un concepto'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {(fields, { add, remove }, {errors}) => (
              <>
                {fields.map((field) => (
                  <Row key={field.key} className="mb-1" align="baseline">
                    <Col span={11} className="pr-2">

                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.conciliationManualLines !== curValues.conciliationManualLines}
                      >
                        {() => (
                          <Form.Item
                            className="mb-1"
                            {...field}
                            name={[field.name, 'concept']}
                            rules={defaultRequiredRules(t('error_empty_field'))}
                          >
                            <Select
                              className="select-modal"
                              allowClear
                              placeholder={t('hint_concept')}
                              optionFilterProp="children"
                              options={conciliation?.conceptos}
                              fieldNames={{ label: 'label', value: 'value' }}
                            />
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={11} className="pr-2">
                      <Form.Item
                        className="mb-1"
                        {...field}
                        rules={defaultRequiredRules(t('error_empty_field'))}
                        name={[field.name, 'serviceAmount']}
                      >
                        <InputNumber
                          prefix={<EuroCircleOutlined className="icon" />}
                          placeholder={t('text_amount_payment')}
                          decimalSeparator=","
                          controls={false}
                          className="input-modal"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} className="mt-2">
                      <MinusCircleOutlined onClick={() => remove(field.name)} />
                    </Col>
                  </Row>
                ))}
                <Row className="my-2" align="middle" justify="center">
                  <Col>
                    <Form.ErrorList errors={errors} />
                  </Col>
                </Row>
                
              </>
            )}
          </Form.List>
        </div>
        <Form.Item
          name="paymentAmount"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <InputNumber
            prefix={<EuroCircleOutlined className="icon" />}
            decimalSeparator=","
            placeholder={t('text_abono_lm')}
            controls={false}
            className="input-modal"
          />
        </Form.Item>
        <Form.Item
          name="observation"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <TextArea
            showCount
            allowClear
            placeholder={t('text_observation')}
            rows={3}
            maxLength={130}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

NewManualLinesModal.defaultProps = {
  conciliation: null,
  handleOk: undefined,
  handleCancel: undefined,
  hotel: null,
};

NewManualLinesModal.propTypes = {
  conciliation: PropTypes.shape({
    lineas: PropTypes.arrayOf(PropTypes.shape({
      id_hotel: PropTypes.number,
      hotel: PropTypes.string || PropTypes.number,
    })),
    conceptos: PropTypes.arrayOf(PropTypes.shape({
    })),
  }),
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  hotel: PropTypes.string || PropTypes.number,
  jobId: PropTypes.number.isRequired,
};
